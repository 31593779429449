<template>
  <v-container fluid>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn href="https://admin.gymkirchenfeld.ch/wiki" target="_blank" text>
        <span class="mr-2">wiki</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <v-btn href="https://intern.gymkirchenfeld.ch" target="_blank" text>
        <span class="mr-2">Intern</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <v-row class="flex-nowrap justify-start align-start overflow-auto">
      <v-col cols="auto">
        <TeacherExit
          v-if="info && info.teacherExit"
          :items="info.teacherExit"
        />
      </v-col>
      <v-col cols="auto">
        <EventoImport
          v-if="info && info.eventoImport"
          :data="info.eventoImport"
        />
      </v-col>
      <v-col cols="auto">
        <AbsenceBalance
          v-if="info && info.absenceBalance"
          :items="info.absenceBalance"
      /></v-col>
      <v-col cols="auto">
        <Birthday v-if="info && info.birthday" :items="info.birthday" />
      </v-col>
      <v-col cols="auto">
        <ChangeLog />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AbsenceBalance from '@/components/modules/AbsenceBalance';
import Birthday from '@/components/modules/Birthday';
import EventoImport from '@/components/modules/EventoImport';
import TeacherExit from '@/components/modules/TeacherExit';
import ChangeLog from '@/components/modules/ChangeLog';
export default {
  name: 'HelloWorld',
  components: {
    AbsenceBalance,
    Birthday,
    EventoImport,
    TeacherExit,
    ChangeLog,
  },
  data() {
    return {
      info: {},
    };
  },
  async created() {
    this.info = await this.apiList({ resource: 'app/overview' });
  },
};
</script>
