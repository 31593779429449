<template>
  <v-card :disabled="this.items.length === 0" width="340">
    <v-system-bar color="intern">
      ChangeLog<v-spacer /> {{ this.items.length }} neuste
    </v-system-bar>
    <v-list>
      <template v-for="(item, index) in items">
        <v-divider :key="'divider' + index" v-if="index > 0" />
        <PersonItem
          :key="'person' + index"
          :value="item.person"
          clickable
        ></PersonItem>
        <v-divider :key="'dividerinset' + index" inset />
        <ChangeItem :key="'change' + index" :value="item"></ChangeItem>
      </template>
    </v-list>
  </v-card>
</template>
<script>
import ChangeItem from "@/components/ChangeItem.vue";
import PersonItem from "@/components/PersonItem.vue";

export default {
  name: "ChangeLog",
  data() {
    return {
      items: [],
    };
  },
  components: { ChangeItem, PersonItem },
  async created() {
    this.items = await this.apiList({
      resource: "person/changelog",
      query: "recent",
    });
  },
};
</script>
