<template>
  <v-card :disabled="this.items.length === 0" width="400">
    <v-system-bar color="error">
      Austritte<v-spacer />{{ this.items.length }} nächstens
    </v-system-bar>

    <v-timeline align-top dense>
      <v-timeline-item
        v-for="item in groups"
        :key="item.date"
        color="error"
        small
        fill-dot
      >
        <DateValue :value="item.date" />
        <PersonItem
          :value="entry"
          clickable
          v-for="entry in item.entries"
          :key="entry.id"
        />
      </v-timeline-item>
    </v-timeline>
  </v-card>
</template>
<script>
import DateValue from "common/components/DateValue.vue";
import PersonItem from "@/components/PersonItem";

export default {
  name: "TeacherExit",
  props: ["items"],
  data() {
    return { groups: [] };
  },

  components: { DateValue, PersonItem },
  created() {
    this.items.forEach((item) => {
      let group = this.groups.find((i) => i.date === item.exitDate);
      if (group) {
        group.entries.push(item);
      } else {
        this.groups.push({ date: item.exitDate, entries: [item] });
      }
    });
    this.groups = this.groups.sort((a, b) =>
      a.date > b.date ? 1 : b.date > a.date ? -1 : 0
    );
  },
};
</script>
