<template>
  <v-card :disabled="this.items.length === 0" width="340">
    <v-system-bar color="warning">
      Geburtstagskinder<v-spacer /> {{ this.items.length }} heute
    </v-system-bar>
    <v-list>
      <PersonItem
        v-for="person in items"
        :key="'person' + person.id"
        :value="person"
        clickable
      ></PersonItem>
    </v-list>
  </v-card>
</template>
<script>
import PersonItem from "@/components/PersonItem.vue";

export default {
  name: "Birthday",
  props: ["items"],
  components: { PersonItem },
};
</script>
